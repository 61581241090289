export default function NotFound() {
    const style = {
        paddingTop: "15%",
        display: "flex",
        justifyContent: "center",
        fontSize: "64px",
        fontWeight: "bolder",
    }

    return (
        <div style={style}>
            404 Not found
        </div>
    )
}